import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Classes en Directe de Psicos i Campus Online (12 hores Novembre/Desembre 2023)
                </Title>
                <Text>
                    Arriba el millor curs online de psicos en directe, ara els divendres!!
                    <br />
                    <br />
                    Un curs totalment renovat i amb exercicis nous en base als oficials
                    <br />
                    <br />
                    12 hores en directe amb Sayd i exercicis a través del campus.
                    <br />
                    <br />
                    El curs s'imparteix:
                    <br />
                    <br />
                    Divendres 17 de novembre de 18.00 a 22.00h
                    <br />
                    <br />
                    Divendres 24 de novembre de 18.00 a 22.00h
                    <br />
                    <br />
                    Divendres 1 de desembre de 18.00 a 22.00h
                    <br />
                    <br />
                    Les classes queden gravades fins el 31 de desembre
                    <br />
                    <br />
                    Al campus trobareu:
                    <br />
                    <br />
                    Vídeos, exercicis i simulacres.
                    <br />
                    <br />
                    L'objectiu principal d'aquest curs de preparació intensiu és donar-vos un impuls
                    de qualitat a l'hora d'afrontar aquesta prova amb les màximes garanties.
                    <br />
                    <br />
                    - Mossos d'Esquadra
                    <br />
                    <br />
                    - Guàrdia Urbana de Barcelona
                    <br />
                    <br />
                    - Policies Locals
                    <br />
                    <br />
                    Cada any molts aspirants es queden fora a aquesta prova i no volem que aquest
                    any això passi amb tu.
                    <br />
                    <br />
                    Recorda que cada raonament que tindràs a la prova aptitudinal és rellevant, i
                    tots ells tenen el mateix valor.
                    <br />
                    <br />
                    Tant el raonament abstracte, l'espacial, verbal, numèric i perceptiu són bàsics
                    per superar-la.
                    <br />
                    <br />
                    Cada lògica es tractarà de forma individual i amb exercicis on detallarem tot el
                    que necessites per poder tenir èxit en aquesta prova.
                    <br />
                    <br />
                    Explicació i anàlisi de tots els raonaments que surten a la prova (raonament
                    abstracte, espacial, verbal, numèric i perceptiu).
                    <br />
                    <br />
                    T'esperem!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
